import React from "react"
import { Wide } from "../../components/layout"
import Layout from "../../components/layout"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"


const Download = () => {
  return (
    <Layout>
      <Helmet
        htmlAttributes={{
          lang: 'nl',
        }}
        title="Download Vonk Magazine"
        meta={[
          {
            name: "robots",
            content: "noindex",
          }
        ]}
        />
      <Wide>
        <div className="grid grid-cols-2 grid-rows-1 gap-6 sm:gap-12 mx-4">
          <div className="col-span-2 md:col-span-1">
            <StaticImage src="../../../content/images/vm2023-2/cover.jpg" alt="Vonk Magazine 2023-1" />
          </div>
          <div className="col-span-2 md:col-span-1">
            <div className="max-w-3xl m-auto px-4">
              <p>Download Vonk Magazine 2023 - 2 voor Vonk+ abonnees</p>
              <p className="mt-4">
                <a className="font-sans inline-block cursor-pointer rounded-md border border-transparent px-5 py-3 bg-teal-500 text-base font-medium text-teal-100 shadow hover:bg-teal-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-teal-600 sm:px-10" href="/PNuyTg/Vonk Magazine 2023 - 2.pdf" target="_blank">PDF</a>
              </p>
              <p className="mt-4">
                <a className="font-sans inline-block cursor-pointer rounded-md border border-transparent px-5 py-3 bg-teal-500 text-base font-medium text-teal-100 shadow hover:bg-teal-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-teal-600 sm:px-10" href="/PNuyTg/Vonk Magazine 2023 - 2.epub" target="_blank">EPub</a>
              </p>
            </div>
          </div>
        </div>
        
      </Wide>
    </Layout>
  )
}

export default Download;
